import ReactGA from "react-ga4";

const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const initGA = () => {
  ReactGA.initialize(measurementId);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
