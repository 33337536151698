import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import "./Menu.css";
const { SubMenu } = Menu;

const AppMenu = (props) => {
  const menuItems = [
    {
      key: "home",
      label: "Home",
    },
    {
      key: "country_page",
      label: "Country Page",
    },
  ];

  const onClickHandler = (e) => {
    // redirect to the home page
    if (e.key === "home") {
      window.location.href = "/";
    }
    if (e.key === "about-1") {
      window.location.href = "/about";
    }
    if (e.key === "release-1") {
      window.location.href = "/releases";
    }
  };

  const items = [
    { label: "Home", key: "home", onClick: onClickHandler }, // remember to pass the key prop
    {
      label: "Info",
      key: "submenu",
      children: [
        {
          label: "About",
          key: "about-1",
          onClick: onClickHandler,
        },
        {
          label: "Release Notes",
          key: "release-1",
          onClick: onClickHandler,
        },
      ],
    },
  ];
  return <Menu mode="horizontal" items={items} />;
};

export default AppMenu;
