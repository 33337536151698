import { createContext, useState } from "react";

export const CountryPageContext = createContext({
  currentNetworkType: [],
  setCurrentNetworkType: () => {},
});

export const CountryPageProvider = ({ children }) => {
  const [currentNetworkType, setCurrentNetworkType] = useState([]);

  return (
    <CountryPageContext.Provider
      value={{ currentNetworkType, setCurrentNetworkType }}
    >
      {children}
    </CountryPageContext.Provider>
  );
};
