import React from "react";

import "./Footer.css";

const Footer = (props) => {
  return (
    <footer className="wdqms-footer">
      <span className="wmo_copy">&copy; WMO</span>
      <span className="disclaimer">
        Materials presented on this website, particularly maps and territorial
        information, do not imply the expression of any opinion whatsoever on
        the part of the Secretariat of the World Meteorological Organization
        concerning the legal status of any country, territory, city or area or
        of its authorities, or concerning the delimitation of its frontiers or
        boundaries.
      </span>
      <span className="version"> 
        Version: <a href="/releases">{process.env.REACT_APP_VERSION}</a>
      </span>
    </footer>
  );
};

export default Footer;
