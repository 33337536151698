import React from "react";

import "./ReleaseNotes.css";
const ReleaseNotes = (props) => {
  return (
    <>
      
       
      <div className="releasenotes">
      <h2 className="page-title">Release Notes:</h2>
      <div>
        <h4>v1.2.1: Release (23/07/2024)</h4>
        <ul>
        <li>Bugfix: Number of compliant Members on landing page showed number of comliant Members for Upper-air</li>
        <li>Number of compliant Members broken down by network</li>        
        </ul>
      </div>
      <div>
        <h4>v1.2: Release (27/05/2024)</h4>
        <ul>
        <li>Advice function added to Member page</li>
        <li>All baseline re-calculated for upper-air using only complete observations</li>
        
        </ul>
      </div>
      <div>
        <h4>v1.1: Service release (8/05/2024)</h4>
        <ul>
          <li>Network type filter in map acts on station list</li>
          <li>Bug-fixes, layout and spelling improvements</li>
        </ul>
      </div>
      <div>
        <h4>v1.0: Initial release (16/04/2024)</h4>
      </div>
      </div>
    </>
  );
};

export default ReleaseNotes;
