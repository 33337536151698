import React from "react";

import "./About.css";
const About = (props) => {
  return (
    <>
      <center>
        <h2 className="page-title">About the GBON compliance app</h2>
      </center>
      <div className="compliance">
        <ul>
          <li>The GBON Compliance App publishes the compliance of WMO Members with Global Basic Observing Network (GBON) regulations (<a href="https://library.wmo.int/records?search=&fulltext=1&refine%5bWMO_number%5d%5b%5d=1160&search_id=1160&id_type=WMO_number&any_lang=1&sort=_score&perpage=10&page=1&&page=1">Manual on the WMO Integrated Global Observing System, WMO-No. 1160</a>) each quarter.</li>
          <li>Compliance with GBON requires meeting the Surface and Upper-air temporal and spatial reporting <a href="https://community.wmo.int/en/activity-areas/wigos/gbon">requirements</a>.</li>
          <li>The GBON compliance criteria, based on Section 11.4 of the <a href="https://library.wmo.int/records?search=&fulltext=1&refine%5bWMO_number%5d%5b%5d=1165&search_id=1165&id_type=WMO_number&any_lang=1&sort=_score&perpage=10&page=1&&page=1">Guide to the WMO Integrated Global Observing System, WMO-No. 1165</a> established and further developed by the <a href="https://community.wmo.int/en/governance/commission-membership/commission-observation-infrastructure-and-information-systems-infcom/infcom-management-group/task-team-implementation-global-basic-observing-network-tt-gbon">Task Team on the Implementation of the Global Basic Observing Network</a>, determine compliance.</li>
          <li>The target number of stations for a Member is a function of the surface area of the Member or geographic area.</li>
          <li>To be considered, a station needs to have reported, as monitored by <a href="https://wdqms.wmo.int">WDQMS</a>, at least 80% of expected observations on at least 80% of days during the quarter.</li>
          <li>To be considered, a station also needs to be assigned to GBON in <a href="https://oscar.wmo.int/surface">OSCAR/Surface</a> on at least one day of the quarter.</li>
          <li>The gap is the difference between the target number of stations and the number of stations meeting the temporal reporting requirement.</li>
          <li>The compliance monitoring process considers, and the app displays, exemptions from the WMO Technical Regulations requested by a Member under Article 9.</li>
          <li>If a WMO Member is composed of several geographic areas, the gap is aggregated, by adding up the gap. The effect of this is that a potential gap in one area is not reduced by a potential surplus of reporting stations in another area. For such WMO Members, the app displays separate gap figures for princial geographic and other areas, for conveniece.</li>
          <li>The main page shows global GBON implementation aggregated by geographic area for Surface and Upper-air, and can therefor differ from the Member-level compliance information shown on the Member page.</li>
          <li>When selecting the "All" baseline, the compliance monitoring applies a lower reporting threshold to upper-air stations, than when selecting the GBON basline. This is why an upper-air station can under certain conditions appear as reporting in the "All" baseline and not in the GBON one.</li>
          <li>For questions regarding this App, please contact the Secretariat, <a href="mailto:afischer_at_wmo.int">Albert Fischer</a>, Director of WIGOS, Infrastructure Department.</li>
        </ul> 
      </div>
    </>
  );
};

export default About;
