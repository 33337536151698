import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import AppMenu from "./components/Menu/Menu";
import CountryPage from "./components/CountryPage/CountryPage";
import Footer from "./components/Footer/Footer";
import CountryPageFilters from "./components/CountryPageFilters/CountryPageFilters";
import AppRouter from "./AppRouter";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import { useLocation } from "react-router-dom";
import { initGA, logPageView } from "./Analytics";
import { useEffect } from "react";
import ReleaseNotes from "./components/ReleaseNotes/ReleaseNotes";

function App() {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, [location]);
  return (
    <div className="level1">
      <Header />
      <AppMenu />
      {/* <Router> */}
      <Routes>
        <Route
          path="/country/map/:country/:resolution/:baseline/:year/:quarter"
          exact
          // render={(props) => <CountryPage params={props.match.params} />}
          element={<CountryPage />}
        />
        {/* <Route path="/gbon-compliance" exact element={<Home />} /> */}
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} exact />
        <Route path="/releases" element={<ReleaseNotes />} exact />
      </Routes>
      {/* </Router> */}
      {/* <CountryPageFilters /> */}
      <div className="app-content">{/* <CountryPage /> */}</div>
      <Footer />
    </div>
  );
}

export default App;
