import React from "react";
import { useState, useEffect, useContext } from "react";

import "./ObservationsTbl.css";
import { apiEndpoint } from "../../Api.js";
import { useParams } from "react-router";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { CountryPageContext } from "../../contexts/CountryPageContext.js";

const ObservationsTbl = (props) => {
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const params = useParams();
  const [stations, setStations] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredStations, setFilteredStations] = useState([]);
  const { currentNetworkType } = useContext(CountryPageContext);
  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },

      wigosid: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Station Name or Wigosid"
          />
        </span>
      </div>
    );
  };

  const complianceTemplate = (rowData) => {
    let compliance_percent =
      (rowData.received_days / rowData.target_days) * 100;
    compliance_percent = Math.round((compliance_percent * 100) / 100);
    return (
      <span>
        {compliance_percent}% ({rowData.received_days}/{rowData.target_days})
      </span>
    );
  };

  const infoTemplate = (rowData) => {
    let exemption = rowData.exemption;
    let reporting = rowData.reporting;
    let reporting_gap = rowData.reporting_gap;

    let exemptionSpan = "";
    if (exemption) {
      exemptionSpan = <span className="exemption">Exemption</span>;
    }

    let reportingSpan = "";
    if (reporting) {
      reportingSpan = <span className="reporting">Reporting</span>;
    } else if (reporting_gap) {
      reportingSpan = (
        <span className="reporting-gap">Reporting partially</span>
      );
    }
    return (
      <span>
        {reportingSpan} {exemptionSpan}
      </span>
    );
  };

  const typeTemplate = (rowData) => {
    let type = rowData.type;
    if (type === "surface") {
      return <span>Surface</span>;
    } else {
      return <span>Upper-air</span>;
    }
  };

  const wigosidTemplate = (rowData) => {
    let url =
      "https://oscar.wmo.int/surface/index.html#/search/station/stationReportDetails/" +
      rowData.wigosid;
    return (
      <div>
        <a href={url}>{rowData.wigosid}</a>
      </div>
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        severity="secondary"
      ></Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        severity="success"
      ></Button>
    );
  };

  const filterFooterTemplate = () => {
    return <div className="px-3 pt-0 pb-3 text-center">Filter by wigosid</div>;
  };

  const header = renderHeader();

  useEffect(() => {
    let iso3 = params.country;
    let quarter = params.quarter;
    let year = params.year;
    let baseline = params.baseline.toLowerCase();

    let url =
      apiEndpoint +
      `/stations_obs?iso3=${iso3}&quarter=${quarter}&year=${year}&baseline=${baseline}`;
    (async () => {
      const data = await fetch(url).then((res) => res.json());
      setStations(data);
      setLoading(false);
    })();
    initFilters();
  }, []);

  const handleSelectionChange = (e) => {
    setSelectedProduct(e.value);
  };
  const statusBodyTemplate = (rowData) => {
    let status_text = "";
    let tooltip_text = "";
    if (rowData.station_color === "green") {
      status_text = "Compliant";
      tooltip_text = "Shares at least 80% of expected daily observations and on 80% of days";
    } else if (rowData.station_color === "orange") {
      status_text = "Frequency issues";
      tooltip_text = "Shares at least 30% of expected daily observations and on 60% of days";
    } else if (rowData.station_color === "red") {
      status_text = "Availability issues";
      tooltip_text = "Less than 30% of expected daily observations or on less than 60% of days";
    } else {
      status_text = "No data";
      tooltip_text = "No data shared during quarter";
    }
    if (rowData.exemption) {
      return (
        <>
          <span className={`tooltip`}>
            <Tag value={status_text} severity={getSeverity(rowData)}></Tag>
            <span className="tooltiptext">{tooltip_text}</span>
          </span>

          <Tag value={"Exemption"} severity={"exemption"}></Tag>
        </>
      );
    }
    return (
      <span className={`tooltip badge_station`}>
        <Tag value={status_text} severity={getSeverity(rowData)}></Tag>
        <span className="tooltiptext">{tooltip_text}</span>
      </span>
    );
    // return <Tag value={status_text} severity={getSeverity(rowData)}></Tag>;
  };
  const getSeverity = (rowData) => {
    let tag_class = "";

    if (rowData.station_color === "green") {
      tag_class = "compliant";
    } else if (rowData.station_color === "orange") {
      tag_class = "frequency_issue";
    } else if (rowData.station_color === "red") {
      tag_class = "availability_issue";
    } else {
      tag_class = "no_data";
    }
    return tag_class;
  };

  useEffect(() => {
    let colorsToKeep = [];
    // filter stations based on the currentNetworkType
    let mystations = stations;
    if (currentNetworkType.length !== 0) {
      mystations = stations.filter((station) => {
        return currentNetworkType.includes(station.type);
      });
    }

    // iterate over the MapLegendFilters and get the colors
    for (let key in props.MapLegendFilters) {
      colorsToKeep.push(props.MapLegendFilters[key][2]);
    }
    // remove first item "[any]" from colorsToKeep
    colorsToKeep.shift();
    if (colorsToKeep.length !== 0) {
      let filtered = mystations.filter((station) => {
        return colorsToKeep.includes(station.station_color);
      });
      setFilteredStations(filtered);
    } else if (
      colorsToKeep.length === 0 &&
      props.MapLegendFilters.length === 1 &&
      props.MapLegendFilters[0] === "any"
    ) {
      let filtered = mystations.filter((station) => {
        return ["none"].includes(station.station_color);
      });
      setFilteredStations(filtered);
    } else {
      setFilteredStations(mystations);
    }
  }, [stations, props.MapLegendFilters, currentNetworkType]);

  return (
    <div className="obstbl_wrapper">
      <DataTable
        selectionMode="single"
        selection={selectedProduct}
        onSelectionChange={handleSelectionChange}
        value={filteredStations}
        paginator
        showGridlines
        // rowClassName={rowClass}
        rows={5}
        loading={loading}
        dataKey="id"
        filters={filters}
        globalFilterFields={["name", "wigosid", "received", "target"]}
        header={header}
        emptyMessage="No Stations found"
        tableStyle={{ tableLayout: "auto" }}
      >
        <Column
          sortable
          field="name"
          header="Name"
          // filter
          filterPlaceholder="Search by name"
          // style={{ minWidth: "6rem" }}
          filterMenuStyle={{ width: "4rem" }}
          style={{ width: "200px" }}
          headerTooltip="Station Name"
          headerTooltipOptions={{ position: "top" }}
        />
        <Column
          sortable
          field="wigosid"
          header="WIGOS ID"
          filterField="wigosid"
          filterMenuStyle={{ width: "4rem" }}
          // style={{ minWidth: "6rem" }}
          // filter
          filterPlaceholder="Search by WIGOS ID"
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          filterFooter={filterFooterTemplate}
          body={wigosidTemplate}
          style={{ width: "150px" }}
          headerTooltip="WIGOS Station Identifier"
          headerTooltipOptions={{ position: "top" }}
        />
        <Column
          sortable
          field="type"
          header="Type"
          filterField="type"
          showFilterMatchModes={false}
          filterMenuStyle={{ width: "1rem" }}
          style={{ width: "20px" }}
          headerTooltip="Station Type"
          headerTooltipOptions={{ position: "top" }}
          body={typeTemplate}
        />

        {/* <Column
          sortable
          field="received"
          header="Rec."
          filterField="representative"
          showFilterMatchModes={false}
          filterMenuStyle={{ width: "3rem" }}
          style={{ minWidth: "3rem" }}
          headerTooltip="Number of received observations"
          headerTooltipOptions={{ position: "top" }}
        />
        <Column
          sortable
          field="target"
          header="Targ."
          filterField="date"
          dataType="date"
          style={{ minWidth: "3rem" }}
          headerTooltip="Number of target observations"
          headerTooltipOptions={{ position: "top" }}
        /> */}

        <Column
          header="Per."
          showFilterMatchModes={false}
          filterMenuStyle={{ width: "5rem" }}
          style={{ minWidth: "5rem" }}
          body={complianceTemplate}
          headerTooltip="Percentage of days with at least 80% of required observations"
          headerTooltipOptions={{ position: "top" }}
        />
        {/* <Column
          header="Info"
          style={{ minWidth: "3rem" }}
          body={infoTemplate}
          headerTooltip="Station information"
          headerTooltipOptions={{ position: "top" }}
        /> */}
        <Column
          sortable
          field="station_color"
          header="Status"
          filterField="station_color"
          showFilterMatchModes={false}
          filterMenuStyle={{ width: "4rem" }}
          style={{ width: "200px" }}
          headerTooltip="Status"
          headerTooltipOptions={{ position: "top" }}
          body={statusBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default ObservationsTbl;
