import React from "react";

import "./w3.css";
import "./FileTypeChart.css";
import { ChartsText, axisClasses } from "@mui/x-charts";
import Box from "@mui/material/Box";
import { ChartsReferenceLine } from "@mui/x-charts/ChartsReferenceLine";
import Paper from "@mui/material/Paper";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { BarPlot } from "@mui/x-charts/BarChart";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";

const staticTicks = [0, 20, 40, 60, 80, 100];

const FileTypeChart = (props, data) => {
  const [isResponsive, setIsResponsive] = React.useState(false);
  const Container = isResponsive ? ResponsiveChartContainer : ChartContainer;
  const sizingProps = isResponsive ? {} : { width: "100%", height: 400 };
  // if values of surface and upper air are > 100 then set them to 100
  if (props.data.length > 0) {
    // iterate through all the data and set the value to 100 if it is greater than 100
    for (let i = 0; i < props.data.length; i++) {
      // round the value to 2 decimal places for surface and upper air
      props.data[i].Surface = Math.round(props.data[i].Surface * 100) / 100;
      props.data[i].UpperAir = Math.round(props.data[i].UpperAir * 100) / 100;

      if (props.data[i].Surface > 100) {
        props.data[i].Surface = 100;
      }
      if (props.data[i].UpperAir > 100) {
        props.data[i].UpperAir = 100;
      }
    }
  }

  if (props.data.length === 0) {
    return <div></div>;
  } else {
    // get upper air data from props.data
    let upperAirData = props.data.map((item) => {
      return item.UpperAir;
    });
    // get surface data from props.data
    let surfaceData = props.data.map((item) => {
      return item.Surface;
    });
    // get name data from props.data
    let nameData = props.data.map((item) => {
      return item.name;
    });
    let series = [
   
      {
        data: surfaceData,
        label: "Surface",
        valueFormatter: (v) => v + "%",
        type: "bar",
      },
      {
        data: upperAirData,
        label: "UpperAir",
        valueFormatter: (v) => v + "%",
        type: "bar",
      },
    ];
    return (
      <div className="ftype_chart">
        <span className="chart_block" id="chart_1">
          <Box sx={{ width: "100%", maxWidth: 500 }}>
            <span className="mychart_title">
              Evolution of Member compliance for surface and upper-air
            </span>
            <Paper sx={{ width: "100%", height: 400 }} elevation={3}>
              <Container
                margin={{ right: 15 , top: 80}}
                series={series}
                xAxis={[
                  {
                    data: nameData,
                    scaleType: "band",
                    id: "x-axis-id",
                    label: "Quarter / Year",
                    type: "category",
                  },
                ]}
                yAxis={[
                  {
                    //label: "Percentage of target resolution",
                    domain: [0, 100],
                    tick: { staticTicks },
                    valueFormatter: (v) => v + "%",
                    min: 0,
                    max: 100,
                    id: "y-axis-id",
                    scaleType: "linear",
                    type: "number",
                  },
                ]}
                colors={[ "#e6ecc9","#bad8e3"]}
                tooltip={{ trigger: "axis", show: true }}
                width={400}
                height={400}
                type="bar"
              >
                <BarPlot />

                <ChartsReferenceLine
                  y={100}
                  label="Percentage of GBON target"
                  labelAlign="end"
                />
                <ChartsTooltip trigger="axis"/>
                <ChartsLegend />
                <ChartsXAxis
                  label="Quarter / Year"
                  position="bottom"
                  axisId="x-axis-id"
                />
                <ChartsYAxis
                  //label="Percentage of target resolution"
                  position="left"
                  axisId="y-axis-id"
                />
              </Container>
            </Paper>
          </Box>
        </span>
      </div>
    );
  }
};

export default FileTypeChart;
