import React from "react";
import "./CountryPageFilters.css";
import reducer from "../reducers"; // Import the reducer
import { useReducer } from "react";
import { useState, useEffect } from "react";
import { apiEndpoint } from "../../Api";
import { useParams } from "react-router";
// get the list of members as a dropdown
function getCountriesListHtml(elements, members, iso3) {
  for (let i = 0; i < members.length; i++) {
    var selected = false;
    if (members[i]["iso3"] == iso3) {
      selected = iso3;
    }
    elements.push(
      <option key={i} selected={selected} value={members[i]["iso3"]}>
        {members[i]["name"]}
      </option>
    );
  }
  return elements;
}
// get the list of options as a dropdown
function getListHtml(elements, options, selected_option) {
  for (let i = 0; i < options.length; i++) {
    var selected = false;
    if (options[i] == selected_option) {
      selected = "selected";
    }
    elements.push(
      <option key={i} selected={selected} value={options[i]}>
        {options[i]}
      </option>
    );
  }
  return elements;
}

// get the list of resolutions as a dropdown
function getListResolutionHtml(elements, options, selected_option) {
  for (let i = 0; i < options.length; i++) {
    var selected = false;
    let option = "Standard";
    if (options[i] == "highres") {
      option = "High Density";
    }
    if (options[i] == selected_option) {
      selected = "selected";
    }
    elements.push(
      <option key={i} selected={selected} value={options[i]}>
        {option}
      </option>
    );
  }
  return elements;
}

const CountryPageFilters = (props) => {
  const params = useParams();
  const [members, setMembers] = useState([]);
  const [availbleYearsQuarters, setAvailableYearsQuarters] = useState([]);
  let iso3 = params.country;
  let resolution = params.resolution;
  let baseline = params.baseline;
  let year = params.year;
  let quarter = params.quarter;

  useEffect(() => {
    (async () => {
      const data = await fetch(apiEndpoint + "/member").then((res) =>
        res.json()
      );
      setMembers(data);
    })();
  }, []);

  // get availableYearsQuarters
  useEffect(() => {
    (async () => {
      const data = await fetch(apiEndpoint + "/available_years_quarters").then(
        (res) => res.json()
      );
      setAvailableYearsQuarters(data);
    })();
  }, []);

  // Get the list of members
  let members_options = [];

  members_options = getCountriesListHtml(members_options, members, iso3);

  // Get the list of resolutions
  let resolutions_options = [];

  let resolutions = ["standard", "highres"];
  resolutions_options = getListResolutionHtml(
    resolutions_options,
    resolutions,
    resolution
  );

  // Get the list of baselines
  let baselines_options = [];

  let baselines = ["GBON", "All"];
  baselines_options = getListHtml(baselines_options, baselines, baseline);

  // Get the list of quarters
  let quarters_options = [];

  // get quarter from the url for a given year
  if (availbleYearsQuarters.length != 0) {
    let quarters = availbleYearsQuarters[year];
    quarters_options = getListHtml(quarters_options, quarters, quarter);
  }
  // Get the list of years
  let years_options = [];
  if (availbleYearsQuarters.length != 0) {
    let years = Object.keys(availbleYearsQuarters);
    years_options = getListHtml(years_options, years, year);
  }

  // OnChange Events for the filters
  // Handle the change of member filter
  const handleCountryChange = (e) => {
    window.location.href =
      window.location.origin +
      `/country/map/${e.target.value}/${resolution}/${baseline}/${year}/${quarter}`;
  };
  // Handle the change of resolution filter
  const handleResolutionChange = (e) => {
    window.location.href =
      window.location.origin +
      `/country/map/${iso3}/${e.target.value}/${baseline}/${year}/${quarter}`;
  };
  // Handle the change of baseline filter
  const handleBaselineChange = (e) => {
    window.location.href =
      window.location.origin +
      `/country/map/${iso3}/${resolution}/${e.target.value}/${year}/${quarter}`;
  };
  // Handle the change of quarter filter
  const handleQuarterChange = (e) => {
    // quarter = e.target.value.replace("Q", "q");
    window.location.href =
      window.location.origin +
      `/country/map/${iso3}/${resolution}/${baseline}/${year}/${e.target.value}`;
  };
  // Handle the change of year filter
  const handleYearChange = (e) => {
    // get availble quarters for the selected year
    let quarters = availbleYearsQuarters[e.target.value];
    // if quarter is not in the list of available quarters for the selected year
    // take the first quarter
    if (!quarters.includes(quarter)) {
      quarter = quarters[0];
    }
    window.location.href =
      window.location.origin +
      `/country/map/${iso3}/${resolution}/${baseline}/${e.target.value}/${quarter}`;
  };

  return (
    <>
      <div className="title-map">GBON Compliance</div>
      <div id="menu" className="country-page-filter">
        <span className="menu-element select-countries">
          <label htmlFor="menu-countries">Member:</label>
          <select
            className="select-css"
            id="menu-countries"
            name="countries"
            onChange={handleCountryChange}
          >
            {members_options}
          </select>
        </span>
        <span className="menu-element select-resolution">
          <label htmlFor="menu-resolution">Resolution:</label>
          <select
            className="select-css"
            id="menu-resolution"
            name="resolution"
            onChange={handleResolutionChange}
          >
            {resolutions_options}
          </select>
        </span>
        <span className="menu-element select-countries">
          <label htmlFor="menu-baseline">Baseline:</label>
          <select
            className="select-css"
            id="menu-baseline"
            name="baseline"
            onChange={handleBaselineChange}
          >
            {baselines_options}
          </select>
        </span>
        <span className="menu-element select-quarter">
          <label htmlFor="menu-quarter">Quarter:</label>
          <select
            className="select-css"
            id="menu-quarter"
            name="quarter"
            onChange={handleQuarterChange}
          >
            {quarters_options}
          </select>{" "}
        </span>

        <span className="menu-element select-years">
          <label htmlFor="menu-years">Year:</label>
          <select
            className="select-css"
            id="menu-years"
            name="years"
            onChange={handleYearChange}
          >
            {years_options}
          </select>
        </span>
      </div>
    </>
  );
};

export default CountryPageFilters;
