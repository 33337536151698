import React from "react";

import "./CountryPage.css";
import CountryMap from "../CountryMap/CountryMap";
import FileTypeChart from "../FileTypeChart/FileTypeChart";
import { useReducer } from "react";
import { useState, useEffect } from "react";

import reducer from "../reducers"; // Import the reducer
import CountryPageFilters from "../CountryPageFilters/CountryPageFilters";

import { apiEndpoint } from "../../Api";
import { useParams } from "react-router";

import countriesCentroids from "../CountryMap/country-centroids.json";

// http://127.0.0.1:5000/api/1.0/member_status?iso3=BLR&quarter=3&year=2023&baseline=gbon&resolution=standard

function produceAdvice(
  data_principal,
  data_other,
  multi_country,
  network_type
) {
  let frequency = "hourly";
  let stationType = "surface";
  if (network_type == "upperair") {
    frequency = "twice daily";
    stationType = "upper-air";
  }

  let advice_txt = "";

  if (!multi_country) {
   advice_txt = (
    <ul>
      { data_principal["gap_assign"] > 0 && (<li>Assign {data_principal["gap_assign"]} existing station(s) to GBON</li>)}
      { data_principal["gap_frequency_gbon"] > 0  && (<li>Increase the reporting frequency of {data_principal["gap_frequency_gbon"]} existing GBON station(s) to {frequency}</li>)}
      { data_principal["gap_frequency_all"] > 0  && (<li>Increase the reporting frequency of {data_principal["gap_frequency_all"]} station(s) to {frequency} and assign to GBON</li>)}
      { data_principal["gap_availability_all"] > 0  && (<li>Enhance {data_principal["gap_availability_all"]} station(s) with availability issues</li>)}
      { data_principal["gap_new"] > 0  && (<li>Add {data_principal["gap_new"]} GBON station(s)</li>)}
    </ul>
  );
  } else {
    let gap_assign = data_principal["gap_assign"] + data_other["gap_assign"];
    let gap_frequency_gbon =
      data_principal["gap_frequency_gbon"] + data_other["gap_frequency_gbon"];
    let gap_frequency_all =
      data_principal["gap_frequency_all"] + data_other["gap_frequency_all"];
    let gap_new = data_principal["gap_new"] + data_other["gap_new"];
    let gap_availability_all = data_principal["gap_availability_all"] + data_other["gap_availability_all"];
    advice_txt = (
      <ul>
        { gap_assign > 0   && (<li>Assign {gap_assign} (<span className={`tooltip`}> 
         
          {data_principal["gap_assign"]}/{data_other["gap_assign"]}
          <span className="tooltiptext">Principal geographic areas: {data_principal["gap_assign"]}<br/>Other geographic areas: {data_other["gap_assign"]}</span>
          </span>) 
          {" "} existing station(s) to GBON</li>)
        }
        { gap_frequency_gbon > 0  && (<li>Increase the reporting frequency of {gap_frequency_gbon} (<span className={`tooltip`}> 
        {" "} 
        {data_principal["gap_frequency_gbon"]}/{data_other["gap_frequency_gbon"]} 
        <span className="tooltiptext">Principal geographic areas: {data_principal["gap_frequency_gbon"]}<br/>Other geographic areas: {data_other["gap_frequency_gbon"]}</span>
        </span>)
        {" "} existing GBON station(s) to {frequency}</li>)}
        { gap_frequency_all > 0 && (<li>Increase the reporting frequency of {gap_frequency_all} (<span className={`tooltip`}>
        {data_principal["gap_frequency_all"]}/{data_other["gap_frequency_all"]} 
        <span className="tooltiptext">Principal geographic areas: {data_principal["gap_frequency_all"]}<br/>Other geographic areas: {data_other["gap_frequency_all"]}</span>
        </span>)
        {" "} station(s) to {frequency} and assign to GBON</li>)}

        { gap_availability_all > 0 && (<li>Enhance {gap_availability_all} (<span className={`tooltip`}> 
        {data_principal["gap_availability_all"]}/{data_other["gap_availability_all"]} 
        <span className="tooltiptext">Principal geographic areas: {data_principal["gap_availability_all"]}<br/>Other geographic areas: {data_other["gap_availability_all"]}</span>
        </span>)
        {" "} station(s) with availability issues</li>)}

        { gap_new > 0 && (<li>Add {gap_new} (<span className={`tooltip`}> 
        {data_principal["gap_new"]}/{data_other["gap_new"]} 
        <span className="tooltiptext">Principal geographic areas: {data_principal["gap_new"]}<br/>Other geographic areas: {data_other["gap_new"]}</span>
        </span>)
        {" "} GBON station(s)</li>)}
      </ul>
    );
  }

  return advice_txt;
}

// This function processes the advice data and returns the advice text
function processAdvice(data, country_name) {
  let countryName = country_name;
  let multi_country = data["info"]["multi_country"] == true;
  let surface_other = data["surface_other"];
  let surface_principal = data["surface_principal"];
  let upper_air_other = data["upper-air_other"];
  let upper_air_principal = data["upper-air_principal"];

  if (data["info"]["compliant"] == true) {
    return "";
  }

  let advice_txt = (
    <div className="text_compliance country_advice_box w3-panel w3-pale-blue w3-leftbar w3-border w3-border-blue">
      <div className="subheader_compliance">Advice:</div>
      <div>To become GBON compliant, {countryName} could:</div>
      {(surface_principal["compliant"] == false ||
        surface_other["compliant"] == false) && (
        <div>
          <div className="surface_advice">Surface:</div>{" "}
          {produceAdvice(
            surface_principal,
            surface_other,
            multi_country,
            "surface"
          )}{" "}
        </div>
      )}
      {(upper_air_principal["compliant"] == false ||
        upper_air_other["compliant"] == false) && (
        <div>
          <div className="ua_advice">Upper-air:</div>{" "}
          {produceAdvice(
            upper_air_principal,
            upper_air_other,
            multi_country,
            "upperair"
          )}{" "}
        </div>
      )}
    </div>
  );
  return advice_txt;
}


function getExemptionsHtml(data, countryIsMultiPlace) {

    let txt =(

      <li>
          Exemptions: Target with exemptions: {data["nr_target"]}
          {" "} 
          { countryIsMultiPlace && (
          <span>{"("}
          <span className={`tooltip`}>
            <span>
            {" "}
            {data["nr_target_principal"]}/
            {data["nr_target_other"]}
          </span>
            <span className="tooltiptext">
              Target number of stations for Principal geographic areas{" "}
              {data["nr_target_principal"]}. Target number of
              stations for other geographic areas{" "}
              {data["nr_target_other"]}.
            </span>
          </span>{")"}</span> ) }
          {". "} Original target: {data["nr_target_original"]}
        </li>

    )

    return txt;

}

const CountryPage = (props) => {
  const [memberStatus, setMemberStatus] = useState([]);
  const [timeseriesData, setTimeseriesData] = useState([]);
  const [memberInfo, setMemberInfo] = useState([]);
  const [countryAdvice, setCountryAdvice] = useState([]);

  const params = useParams();

  let iso3 = params.country;
  let quarter = params.quarter;
  let year = params.year;
  let baseline = params.baseline.toLowerCase();
  let resolution = params.resolution.toLowerCase();
  let chartData = [];
  let p = <div></div>;
  let p2 = <div></div>;
  let p3 = "";
  let p4 = "";

  // Get advice for the country using iso3, quarter and year
  useEffect(() => {
    // only get advice if the resolution is standard and the baseline is gbon
    if (baseline == "gbon" && resolution == "standard") {
      let url =
        apiEndpoint + `/advice?iso3=${iso3}&quarter=${quarter}&year=${year}`;
      (async () => {
        const data = await fetch(url).then((res) => res.json());
        let advice = processAdvice(data, memberInfo["name"]);
        setCountryAdvice(advice);
      })();
    }
  }, [memberInfo["name"]]);

  useEffect(() => {
    let url =
      apiEndpoint +
      `/timeseries?iso3=${iso3}&quarter=${quarter}&year=${year}&baseline=${baseline}&resolution=${resolution}`;
    (async () => {
      const data = await fetch(url).then((res) => res.json());
      setTimeseriesData(data);
    })();
  }, []);

  useEffect(() => {
    let url =
      apiEndpoint + `/member_info?iso3=${iso3}&quarter=${quarter}&year=${year}`;
    (async () => {
      const data = await fetch(url).then((res) => res.json());
      setMemberInfo(data);
    })();
  }, []);

  // get compliance information for that member country
  useEffect(() => {
    let url =
      apiEndpoint +
      `/member_status?iso3=${iso3}&quarter=${quarter}&year=${year}&baseline=${baseline}&resolution=${resolution}`;
    (async () => {
      const data = await fetch(url).then((res) => res.json());
      setMemberStatus(data);
    })();
  }, [memberInfo]);

  if (memberStatus.length > 0) {
    let compliant = "Compliant";
    let countryIsMultiPlace = memberInfo["multi_place"];

    let compliant_class = "w3-badge w3-green";
    let compliant_class_surface = "w3-badge w3-green";
    let compliant_class_ua = "w3-badge w3-green";

    let compliance_status =
      memberStatus[0]["compliant"] == true &&
      memberStatus[1]["compliant"] == true;

    let compliance_status_place_surface =
      memberStatus[0]["compliant"] == false &&
      countryIsMultiPlace &&
      memberStatus[0]["nr_gap"] > 0;
    let compliance_status_place_ua =
      memberStatus[1]["compliant"] == false &&
      countryIsMultiPlace &&
      memberStatus[1]["nr_gap"] > 0;

    let compliance_status_place =
      compliance_status_place_surface || compliance_status_place_ua;

    let countryName = memberInfo["name"];

    let surfaceGap = memberStatus[0]["nr_gap"];
    let uaGap = memberStatus[1]["nr_gap"];

    let up_compliant = "Compliant";
    if (memberStatus[1]["compliant"] == false) {
      up_compliant = "Non-compliant";
      compliant_class_ua = "w3-badge w3-red";
    }
    let surface_compliant = "Compliant";
    if (memberStatus[0]["compliant"] == false) {
      surface_compliant = "Non-compliant";
      compliant_class_surface = "w3-badge w3-red";
    }
    if (compliance_status == false) {
      compliant = "Non-compliant";
      compliant_class = "w3-badge w3-red";
    }

    let gap_note = false;

    let surface_gap_note = "";
    if (
      surfaceGap >
      Math.max(
        0,
        memberStatus[0]["nr_target"] - memberStatus[0]["nr_reporting"]
      )
    ) {
      surface_gap_note = "*";
      gap_note = true;
    }

    let ua_gap_note = "";
    if (
      uaGap >
      Math.max(
        0,
        memberStatus[1]["nr_target"] - memberStatus[1]["nr_reporting"]
      )
    ) {
      ua_gap_note = "*";
      gap_note = true;
    }

    let exemption_surface = "";
    if (memberStatus[0]["has_territorial_exemption"] == true) {
      let multi_s = "";
      if (countryIsMultiPlace) {
        multi_s = (
          <span>
            {" "}
            ({memberStatus[0]["nr_target_principal"]}/
            {memberStatus[0]["nr_target_other"]})
          </span>
        );
      }
      exemption_surface = getExemptionsHtml(memberStatus[0], countryIsMultiPlace);
      
    }

    let exemption_ua = "";
    if (memberStatus[1]["has_territorial_exemption"] == true) {
      let multi_ua = "";
      if (countryIsMultiPlace) {
        multi_ua = (
          <span>
            {" "}
            ({memberStatus[1]["nr_target_principal"]}/
            {memberStatus[1]["nr_target_other"]})
          </span>
        );
      }
      exemption_ua = getExemptionsHtml(memberStatus[1], countryIsMultiPlace);  
    }

    let gap_surface_multi = "";
    if (countryIsMultiPlace) {
      gap_surface_multi = (
        <span>
          ({memberStatus[0]["nr_gap_principal"]}/
          {memberStatus[0]["nr_gap_other"]})
        </span>
      );
    }

    let gap_ua_multi = "";
    if (countryIsMultiPlace) {
      gap_ua_multi = (
        <span>
          ({memberStatus[1]["nr_gap_principal"]}/
          {memberStatus[1]["nr_gap_other"]})
        </span>
      );
    }

    p = (
      <div className="text_compliance">
        Member compliance for {countryName} in {quarter.toUpperCase()} / {year}{" "}
        :{" "}
        <span className={`${compliant_class} tooltip`}>
          {compliant}
          <span className="tooltiptext">
            To be compliant, a Member needs to be compliant with both the
            Surface and Upper-air horizontal resolution and frequency reporting
            requirements.
          </span>
        </span>
        <div className="subheader_compliance">Surface:</div>
        <ul>
          <li>
            Number of reporting stations: {memberStatus[0]["nr_reporting"]}{" "}
            (target: {memberStatus[0]["nr_target"]})
          </li>
          <li>
            Gap: {surfaceGap} {" "}
            { countryIsMultiPlace && ( 
             <span>{ "(" }
             <span className={`tooltip`}>
                      <span>
          {memberStatus[0]["nr_gap_principal"]}/
          {memberStatus[0]["nr_gap_other"]}
        </span>
              
              <span className="tooltiptext">
                Gap in Principal geographic areas{" "}
                {memberStatus[0]["nr_gap_principal"]}. Gap in other geographic
                areas {memberStatus[0]["nr_gap_other"]}.
              </span>
              
            </span>
            { ")" }
            </span>
            
           
           ) }
          {surface_gap_note}
          </li>
          {exemption_surface}
          <li className={"li_surface"}>
            Compliance status:{" "}
            <span className={compliant_class_surface}>{surface_compliant}</span>
            ​
          </li>
        </ul>
        <div className="subheader_compliance" style={{ marginTop: "10px" }}>
          Upper-air:
        </div>
        <ul>
          <li>
            Number of reporting stations: {memberStatus[1]["nr_reporting"]}{" "}
            (target: {memberStatus[1]["nr_target"]})
          </li>
          <li>
            Gap: {uaGap} {" "}
            { countryIsMultiPlace && (
              <span>{ "(" }
            <span className={`tooltip`}>
              
            <span>
          {memberStatus[1]["nr_gap_principal"]}/
          {memberStatus[1]["nr_gap_other"]}
        </span>

              <span className="tooltiptext">
                Gap in Principal geographic areas{" "}
                {memberStatus[1]["nr_gap_principal"]}. Gap in other geographic
                areas {memberStatus[1]["nr_gap_other"]}.
              </span>
            </span>
            { ")" }
            </span>
            )}
            {ua_gap_note}
          </li>
          {exemption_ua}
          <li className={"li_temp"}>
            Compliance status:{" "}
            <span className={compliant_class_ua}>{up_compliant}</span>​
          </li>
        </ul>
      </div>
    );

    if (gap_note) {
      p4 = (
        <li>
          * There is a gap in at least one geographic areas despite there being
          enough total number of reporting stations.
        </li>
      );
    }

    if (countryIsMultiPlace) {
      p2 = (
        <div className="text_compliance">
          <div className="subheader_compliance" style={{ marginTop: "10px" }}>
            Geographic coverage:
          </div>
          <ul>
            <li>
              The gap of {countryName} is calculated on the aggregated gap of
              multiple geographic areas. Please contact the Secretariat for more
              detail.
            </li>
            {p3}
            {p4}
          </ul>
        </div>
      );
    }

    if (timeseriesData.length > 0) {
      // iterate through timeserise data and push it to chartData for upper air and surface

      for (let i = 0; i < timeseriesData.length; i++) {
        // get value of upper air
        let upper_air = "";
        let surface = "";
        let quarter = timeseriesData[i]["member"]["quarter"];
        let year = timeseriesData[i]["member"]["year"];
        if (timeseriesData[i]["type"] == "ua") {
          upper_air = timeseriesData[i]["percent_gap"];
        } else if (timeseriesData[i]["type"] == "surface") {
          surface = timeseriesData[i]["percent_gap"];
        }

        // check if the quarter and year is already in the chartData
        let found = false;
        for (let j = 0; j < chartData.length; j++) {
          if (chartData[j]["name"] == "Q" + quarter + "/" + year) {
            // update surface if it is not empty
            if (surface != "") {
              chartData[j]["Surface"] = surface;
            }
            // update upper air if it is not empty
            if (upper_air != "") {
              chartData[j]["UpperAir"] = upper_air;
            }
            found = true;
          }
        }
        if (!found) {
          chartData.push({
            name: "Q" + quarter + "/" + year,
            Surface: surface,
            UpperAir: upper_air,
          });
        }
      }
    }
  }

  return (
    <>
      <CountryPageFilters props={props} />
      <div className="app-content">
        <div>
          <CountryMap props={props} />
          <div className="placeholer_info">
            {p} {p2} {countryAdvice}
          </div>
          <FileTypeChart data={chartData} props={props} />
        </div>
      </div>
    </>
  );
};

export default CountryPage;
