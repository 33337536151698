import React from "react";
import { useState, useEffect, useContext } from "react";

import "./CountryMap.css";
import ObservationsTbl from "../ObservationsTbl/ObservationsTbl";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import countriesCentroids from "./country-centroids.json";
import { apiEndpoint } from "../../Api.js";
import { useParams } from "react-router";
import { CountryPageContext } from "../../contexts/CountryPageContext";

function getCountryCenter(iso3) {
  for (let i = 0; i < countriesCentroids.length; i++) {
    if (countriesCentroids[i]["iso3"] == iso3) {
      let lon = countriesCentroids[i]["longitude"];
      let lat = countriesCentroids[i]["latitude"];
      return [lon, lat];
    }
  }
}

function findBounds(points) {
  var n = points.length;
  if (n === 0) {
    return [];
  }
  var d = points[0].length;
  var lo = points[0].slice();
  var hi = points[0].slice();
  for (var i = 1; i < n; ++i) {
    var p = points[i];
    for (var j = 0; j < d; ++j) {
      var x = p[j];
      lo[j] = Math.min(lo[j], x);
      hi[j] = Math.max(hi[j], x);
    }
  }
  return [lo, hi];
}
function getCountryDisclaimer(iso3, setCountryDisclaimer) {
  switch (iso3) {
    //For India, Pakistan, and China: "Dotted line represents approximately the Line of Control in Jammu and Kashmir agreed upon by India and Pakistan. The final status of Jammu and Kashmir has not yet been agreed upon by the parties"
    // For Argentina and the UK: "A dispute exists between the Governments of Argentina and the United Kingdom of Great Britain and Northern Ireland concerning sovereignty over the Falkland Islands (Malvinas)"
    // For Sudan and South Sudan: "Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet been determined"
    case "IND":
    case "PAK":
    case "CHN":
      setCountryDisclaimer(
        "Dotted line represents approximately the Line of Control in Jammu and Kashmir agreed upon by India and Pakistan. The final status of Jammu and Kashmir has not yet been agreed upon by the parties"
      );
      break;
    case "ARG":
    case "GBR":
      setCountryDisclaimer(
        "A dispute exists between the Governments of Argentina and the United Kingdom of Great Britain and Northern Ireland concerning sovereignty over the Falkland Islands (Malvinas)"
      );
      break;
    case "SDN":
    case "SSD":
      setCountryDisclaimer(
        "Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet been determined"
      );
      break;
    default:
      setCountryDisclaimer("");
  }
}
const complianceRangesArray = [
  ["Compliant", "green"],
  ["Frequency issues", "orange"],
  ["Availability issues", "red"],
  ["No data received", "black"],
  ["Exemption", "white"],
];
function getVisibleLayers(map) {
  let surfaceVisibility = map.getLayoutProperty("surface", "visibility");
  // get visibiilty of upper-air layer
  let uaVisibility = map.getLayoutProperty("ua", "visibility");
  if (surfaceVisibility === "visible") {
    surfaceVisibility = "surface";
  }
  if (uaVisibility === "visible") {
    uaVisibility = "ua";
  }
  return { surfaceVisibility, uaVisibility };
}

function addDynamicLegend(filters, map, setMapLegendFilters) {
  var legend = document.getElementById("legend");
  if (legend.innerHTML === "") {
    for (let [c_range, color] of complianceRangesArray) {
      var item = document.createElement("div");
      item.className = "legend_class-row";
      var key = document.createElement("span");
      key.className = "legend-key";
      key.style.backgroundColor = color;
      var value = document.createElement("span");
      value.innerHTML = c_range;
      let classLegend = "legend-class-clickable";
      value.className = classLegend;
      if (color === "white") {
        key.style.border = "1px solid red";
        value.className = "legend-class-not-clickable";
      }

      value.onclick = function (e) {
        filterStations(e);

        if (e.target.classList.contains("unselected")) {
          e.target.classList.remove("unselected");
        } else {
          e.target.classList.add("unselected");
        }
      };
      value.appendChild(key);
      item.appendChild(value);
      legend.appendChild(item);
    }
  }

  function filterStations(e) {
    let c_range = e.target.innerText;

    // filter the stations based on the compliance range using the paint property circle-color
    // if compliant is clicked
    if (c_range === complianceRangesArray[0][0]) {
      if (e.target.classList.contains("unselected")) {
        filters.push(["==", "station_color", "green"]);
      } else {
        filters = filters.filter((filter) => filter[2] !== "green");
      }
    }

    // if frequency issues is clicked
    else if (c_range === complianceRangesArray[1][0]) {
      if (e.target.classList.contains("unselected")) {
        filters.push(["==", "station_color", "orange"]);
      } else {
        filters = filters.filter((filter) => filter[2] !== "orange");
      }
    }
    // if availability issues is clicked
    else if (c_range === complianceRangesArray[2][0]) {
      if (e.target.classList.contains("unselected")) {
        filters.push(["==", "station_color", "red"]);
      } else {
        filters = filters.filter((filter) => filter[2] !== "red");
      }
    }
    // if no data received is clicked
    else if (c_range === complianceRangesArray[3][0]) {
      if (e.target.classList.contains("unselected")) {
        filters.push(["==", "station_color", "black"]);
      } else {
        filters = filters.filter((filter) => filter[2] !== "black");
      }
    }
    map.setFilter("surface", filters);
    map.setFilter("ua", filters);
    // get current filter and set it to the state
    setMapLegendFilters(map.getFilter("surface"));
  }
}

const CountryMap = (props) => {
  const key =
    "pk.eyJ1Ijoia3VydC1oZWN0aWMiLCJhIjoiY2loNGtsNzc0MHpvdHgxbTBxejQzOGVpZiJ9.NiOdnMqDcZKeR6Oxo7tWmw";

  const MAPBOX_BACKGROUND_WDQMS =
    "mapbox://styles/kurt-hectic/cj488lfn91qxh2rrz0nghg0y3";
  mapboxgl.accessToken = key;
  const [surfaceStations, setSurfaceStations] = useState([]);
  const [upperAirStations, setUpperAirStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [MapLegendFilters, setMapLegendFilters] = useState([]);
  const [countryDisclaimer, setCountryDisclaimer] = useState("");
  const { currentNetworkType, setCurrentNetworkType } =
    useContext(CountryPageContext);
  const params = useParams();
  let map = null;
  useEffect(() => {
    let iso3 = params.country;
    let quarter = params.quarter;
    let year = params.year;
    let baseline = params.baseline.toLowerCase();
    setCurrentNetworkType(["ua", "surface"]);
    getCountryDisclaimer(iso3, setCountryDisclaimer);
    let surfaceUrl =
      apiEndpoint +
      `/stations_list?iso3=${iso3}&quarter=${quarter}&year=${year}&baseline=${baseline}&type=surface`;
    (async () => {
      const data = await fetch(surfaceUrl).then((res) => res.json());
      setSurfaceStations(data);
    })();

    let upperAirUrl =
      apiEndpoint +
      `/stations_list?iso3=${iso3}&quarter=${quarter}&year=${year}&baseline=${baseline}&type=ua`;
    (async () => {
      const data = await fetch(upperAirUrl).then((res) => res.json());
      setUpperAirStations(data);
    })();
  }, []);

  let center = getCountryCenter(params.country);

  useEffect(() => {
    var zoom = 3;
    if (["USA", "CAN", "RUS"].includes(params.country)) {
      zoom = 1;
    }
    if (["CHN"].includes(params.country)) {
      zoom = 2;
    }
    if (["DEU"].includes(params.country)) {
      zoom = 4;
    }
    if (["KIR"].includes(params.country)) {
      zoom = 3;
    }
    if (["FJI"].includes(params.country)) {
      zoom = 3;
    }

    var bbox = [];
    var station_list = [];
    if (surfaceStations.features) {
      surfaceStations.features.forEach((marker) =>
        station_list.push(marker.geometry.coordinates)
      );
    }
    if (upperAirStations.features) {
      upperAirStations.features.forEach((marker) =>
        station_list.push(marker.geometry.coordinates)
      );
    }
    bbox = findBounds(station_list);

    // check if map has been initialized
    if (map === null) {
      map = new mapboxgl.Map({
        container: "map",
        style: MAPBOX_BACKGROUND_WDQMS,
        center: center,
        zoom: zoom,
      });
    }

    if (
      bbox.length > 0 &&
      station_list.length >= 2 &&
      ["FJI", "KIR"].includes(params.country) === false
    ) {
      map.fitBounds(bbox, {
        padding: { top: 50, bottom: 50, left: 50, right: 50 },
      });
    }
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());

    map.on("load", () => {
      var paint = {
        "circle-radius": 4,
        "circle-color": [
          "case",
          ["==", ["get", "station_color"], "green"],
          "green",
          ["==", ["get", "station_color"], "orange"],
          "orange",
          ["==", ["get", "station_color"], "red"],
          "red",
          "black",
        ],
        "circle-stroke-width": [
          "case",
          ["==", ["get", "exemption"], true],
          1,
          0,
        ],
        "circle-stroke-color": "red",
      };

      if (!map.getLayer("ua") && upperAirStations.features) {
        map.addSource("ua", {
          type: "geojson",
          data: upperAirStations,
        });
        map.addLayer({
          id: "ua",
          type: "circle",
          source: "ua",
          layout: {
            // Make the layer visible by default.
            visibility: "visible",
          },
          paint: paint,
          // "source-layer": "Upper-Air",
        });

        addEventsOnLayer(map, "ua");
      }
      if (!map.getLayer("surface") && surfaceStations.features) {
        map.addSource("surface", {
          type: "geojson",
          data: surfaceStations,
        });
        map.addLayer({
          id: "surface",
          type: "circle",
          source: "surface",
          layout: {
            // Make the layer visible by default.
            visibility: "visible",
          },
          paint: paint,
        });
        addEventsOnLayer(map, "surface");
      }
    });
    let filters;
    filters = [
      "any",
      ["==", "station_color", "green"],
      ["==", "station_color", "orange"],
      ["==", "station_color", "red"],
      ["==", "station_color", "black"],
    ];
    map.on("idle", () => {
      // If these two layers were not added to the map, abort
      if (!map.getLayer("ua") || !map.getLayer("surface")) {
        return;
      } else {
        // add the dynamic legend
        addDynamicLegend(filters, map, setMapLegendFilters);
      }
      // Enumerate ids of the layers.
      const toggleableLayerIds = [
        ["ua", "Upper-air"],
        ["surface", "Surface"],
      ];

      // Set up the corresponding toggle button for each layer.
      for (const l of toggleableLayerIds) {
        // Skip layers that already have a button set up.
        let id = l[0];
        let description = l[1];

        if (document.getElementById(id)) {
          continue;
        }
        // Create a link.
        const link = document.createElement("a");
        link.id = id;
        link.href = "#";
        link.textContent = description;
        link.className = "active";

        // Show or hide layer when the toggle is clicked.
        link.onclick = function (e) {
          const clickedLayer = this.id;
          e.preventDefault();
          e.stopPropagation();

          const visibility = map.getLayoutProperty(clickedLayer, "visibility");

          // Get the current state of the layers for the map
          // Toggle layer visibility by changing the layout object's visibility property.
          if (visibility === "visible") {
            map.setLayoutProperty(clickedLayer, "visibility", "none");
            // map.removeLayer(clickedLayer);
            this.className = "";
          } else {
            this.className = "active";
            map.setLayoutProperty(clickedLayer, "visibility", "visible");
            // map.removeLayer(clickedLayer);
          }

          // get visibiilty of surface layer
          let { surfaceVisibility, uaVisibility } = getVisibleLayers(map);
          setCurrentNetworkType([surfaceVisibility, uaVisibility]);
        };

        const layers = document.getElementById("menu_toggle");
        layers.appendChild(link);
      }
    });
    return () => {
      map.remove();
    };
  }, [surfaceStations, upperAirStations]);

  const onClick = (e) => {
    // show popup infomration of that station
    let coordinates = e.features[0].geometry.coordinates.slice();
    let wigosid = e.features[0].properties.wigosid;
    let name = e.features[0].properties.name;
    let received = e.features[0].properties.received;
    let target = e.features[0].properties.target;
    let type = e.features[0].properties.type;
    let received_days = e.features[0].properties.received_days;
    let received_days_gap = e.features[0].properties.received_days_gap;
    let target_days = e.features[0].properties.target_days;
    let exemption = e.features[0].properties.exemption;
    let target_daily_original = e.features[0].properties.target_daily_original;
    let target_daily = e.features[0].properties.target_daily;

    if (type == "surface") {
      type = "Surface";
    } else if ((type = "ua")) {
      type = "Upper-air";
    }
    let compliance_percent = (received / target) * 100;
    compliance_percent = Math.round((compliance_percent * 100) / 100);

    let exemptionSpan = "";
    if (exemption) {
      exemptionSpan =
        "<span>Exemption: <strong> target " +
        target_daily +
        " daily observations. (original: " +
        target_daily_original +
        ") </strong></span><br>";
    }

    let popup = new mapboxgl.Popup({ offset: 0 })
      .setLngLat(coordinates)
      .setHTML(
        `Wigos-Id: <strong>${wigosid}</strong><br>
      Name: <strong>${name}</strong><br>
      Type: <strong>${type}</strong><p>
      Nr. days reporting (80%): <strong>${received_days}/${target_days}</strong><br>
      Nr. days limited reporting (30%): <strong>${received_days_gap}/${target_days}</strong><br>
      Nr. observations received: <strong>${received}/${target}</strong><p>
      Percent of quarterly observations shared: <strong>${compliance_percent}%</strong><p>
      ${exemptionSpan}
      <a class="link-oscar" target="_blank" rel="noopener noreferrer" href="https://oscar.wmo.int/surface/index.html#/search/station/stationReportDetails/${wigosid}">Open in OSCAR</a>
      </p>`
      );
    popup.addTo(map);
    // new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(map);
  };

  const addEventsOnLayer = (map, layerId) => {
    // When a click event occurs on a feature open a popup at the
    // location of the feature, with description HTML from its properties.
    map.on("click", layerId, onClick);

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on("mouseenter", layerId, function () {
      map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.on("mouseleave", layerId, function () {
      map.getCanvas().style.cursor = "";
    });
  };
  return (
    <div id="map-container">
      {/* <FileTypeTabs /> */}
      <nav id="menu_toggle"></nav>
      <div className="map-overlay" id="legend"></div>
      <div id="map"></div>
      <div className="country-disclaimer">{countryDisclaimer}</div>
      <ObservationsTbl
        props={props}
        selectedStation={selectedStation}
        MapLegendFilters={MapLegendFilters}
      />
    </div>
  );
};

export default CountryMap;
