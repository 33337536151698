import React from "react";
import "./Home.css";
import { useState, useEffect } from "react";
import { apiEndpoint } from "../../Api";
import { HomeImageEndpoint } from "../../Api";

function getCountriesListHtml(elements, members) {
  elements.push(
    <option key={"none"} value="None">
      Select Member
    </option>
  );
  for (let i = 0; i < members.length; i++) {
    elements.push(
      <option key={i} value={members[i]["iso3"]}>
        {members[i]["name"]}
      </option>
    );
  }
  return elements;
}

const Home = (props) => {
  let imageUrl = HomeImageEndpoint + `/compliance.png`;
  const [members, setMembers] = useState([]);
  const [year, setYear] = useState(0);
  const [quarter, setQuarter] = useState(0);
  const [homeImage, setHomeImage] = useState(imageUrl);
  const [homeImageUa, setHomeImageUa] = useState(imageUrl);
  const [homeImageSurface, setHomeImageSurface] = useState(imageUrl);
  const [compliantMembersNumber, setCompliantMembersNumber] = useState(0);
  const [gapSurfaceNumber, setGapSurfaceNumber] = useState(0);
  const [gapUpperAirNumber, setGapUpperAirNumber] = useState(0);
  // Image url is conctain quarter and year

  // get members list
  useEffect(() => {
    (async () => {
      const data = await fetch(apiEndpoint + "/member").then((res) =>
        res.json()
      );
      setMembers(data);
    })();
  }, []);

  useEffect(() => {
    // get the latest quarter and year and set the home image for upper air
    (async () => {
      const data = await fetch(apiEndpoint + "/latest_quarter_year").then(
        (res) => res.json()
      );
      // surface image
      imageUrl =
        HomeImageEndpoint +
        `/compliance-surface-${data["year"]}-${data["quarter"]}.png`;
      setHomeImageSurface(imageUrl);
      // upper air image
      imageUrl =
        HomeImageEndpoint +
        `/compliance-upperair-${data["year"]}-${data["quarter"]}.png`;
      setHomeImageUa(imageUrl);

      setYear(data["year"]);
      setQuarter(data["quarter"]);
    })();
  }, []);

  // get number of compliant members
  useEffect(() => {
    if (quarter !== 0 || year !== 0) {
      (async () => {
        const data = await fetch(
          apiEndpoint +
            `/compliant_members?quarter=${quarter}&year=${year}&baseline=gbon&resolution=standard`
        ).then((res) => res.json());
        // set compliant members number
        setCompliantMembersNumber(data);
      })();
    }
  }, [quarter, year]);

  // get gap surface and upper-air stations number
  useEffect(() => {
    if (quarter !== 0 || year !== 0) {
      (async () => {
        const data = await fetch(
          apiEndpoint +
            `/gap_stations?quarter=${quarter}&year=${year}&baseline=gbon&resolution=standard`
        ).then((res) => res.json());
        // set gap surface number
        setGapSurfaceNumber(data["sum_gap_surface"]);
        // set gap upper-air number
        setGapUpperAirNumber(data["sum_gap_ua"]);
      })();
    }
  }, [quarter, year]);

  // handle change of member from home page
  const handleChange = (e) => {
    var member_selected = e.target.value;
    window.location.href =
      window.location.origin +
      `/country/map/${member_selected}/standard/GBON/${year}/Q${quarter}`;
  };

  // Get the list of members as a dropdown
  let elements = [];
  elements = getCountriesListHtml(elements, members);

  // return if quarter and year and gap surface and upper-air stations are not set to zero
  if (quarter === 0 || year === 0) {
    return <div>Loading</div>;
  } else {
    return (
      <div className="home-content-wrapper">
        <div key={"home-filter"} id="menu" className="country-page-filter-home">
          <span className="menu-element select-countries">
            <label htmlFor="countries">
              Member:
              <select
                className="select-css"
                id="countries"
                name="countries"
                onChange={handleChange}
              >
                {elements}
              </select>
            </label>
          </span>
        </div>
        <div key={"home-content"} className="home_content">
          <div className="home_left">
            <img src={homeImageSurface} className="home_image " />
            <img src={homeImageUa} className="home_image " />
          </div>
          <div className="home_right">
            <div className="stats">
              <h2>
                GBON compliance in Q{quarter} / {year}
              </h2>
              <h4>
                <ul>
                  <li key={1}>
                    Number of compliant Members: {compliantMembersNumber["count"]} (<span className={`tooltip`}> 
                      {compliantMembersNumber["count_surface"]}/{compliantMembersNumber["count_ua"]}<span className="tooltiptext">Number of compliant Members by network: Surface / Upper-air</span></span>)
                  </li>
                  <li key={2}>Gap surface stations: {gapSurfaceNumber}</li>
                  <li key={3}>Gap upper-air stations: {gapUpperAirNumber}</li>
                </ul>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Home;
